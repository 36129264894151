.History-Header {
    text-align: center;
    background-color: #f2f2f2;
    filter: drop-shadow(0px 2px 5px #afafaf);
    position: relative;
    z-index: -100;
}

.History-team-img {
    width: 80%;
    margin-left: 10%;
}

.History-robot-img {
    width: 50%;
    margin-left: 25%;
    margin-top: 20px;
}
