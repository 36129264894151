.ShowcaseVideo video
{
    width:100vw;
    position: absolute;
    z-index: -100;
    background-color: black;
    filter: drop-shadow(0px 2px 5px #333);
}

#videooverlay
{
    text-align: center;
    color: white;
    filter: drop-shadow(0px 0px 7px #000000);
}
    #videooverlay h1
    {
        font-family: 'Righteous', sans-serif;
    }
    #videooverlay a
    {
        color: white;
        text-decoration: none;
    }