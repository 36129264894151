.Sponsors-Header {
    text-align: center;
    background-color: #f2f2f2;
    filter: drop-shadow(0px 2px 5px #afafaf);
}

.Sponsors-sdnb-img {
    width: 80%;
    margin-left: 10%;
}

.Sponsors-img {
    width: 80%;
    margin-left: 10%;
    margin-top: 40px;
}

.Sponsors-img-sm {
    width: 40%;
    margin-left: 30%;
    margin-top: 40px;
}

.Sponsors-btn {
    margin-left: 5%;
}