.Home-Header {
    text-align: center;
    background-color: #f2f2f2;
}

.first-logo {
    width: 80%;
}

.calendar {
    width: 100%;
    height: 100%;
    min-height: 400px;
}