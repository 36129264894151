.Mentors-Header {
    text-align: center;
    background-color: #f2f2f2;
    filter: drop-shadow(0px 2px 5px #afafaf);
    position: relative;
}

.image-formatting {
    position: absolute;
    top: 43%;
    left: 45%;
}

li {
    font-size: 14pt;
}
