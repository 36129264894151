.Footer {
    border-top: 4px solid #F4CC00;
    background-color: #171717;
    font-family: 'Righteous', sans-serif;
}

.btn-foot {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    transition-property: color;
    transition-duration: 100ms;
}
.btn-foot:hover {
    color: #d4b207;
}